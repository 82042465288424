<template>
  <div :class="['Panel', block_form ? 'disabled' : '']">
    <div class="Panel_header">
      <button class="Panel_cancelBtn" v-on:click="$emit('cancel-task', true)">
        <i class="fas fa-chevron-right"></i>
      </button>
      <div class="Panel_title" v-if="project_id === 1">{{ $t("message.panel_sales_title") }}</div>
      <div class="Panel_title" v-if="project_id === 2">{{ $t("message.panel_aftersales_title") }}</div>
      <div class="Panel_title" v-if="project_id === 3">{{ $t("message.panel_sales_title") }}</div>
      <button class="Panel_switchBtn" v-on:click="$emit('switch-view', false)">
        {{ $t("message.switch_views") }}
        <i class="fas fa-angle-double-left Panel_switchBtn--arrow"></i>
      </button>
    </div>
    
    <FormSection
      :priority_options='[{ id: 1, text: $t("message.low")},{ id: 2, text: $t("message.average") }, { id: 3, text: $t("message.high")}]'
      :impact_options='[{ id: 1, text: $t("message.low")},{ id: 2, text: $t("message.average") }, { id: 3, text: $t("message.high")}]'
      v-on:formDone="onFormDone"
      v-on:usersDone="onUsersDone"
      ref="formComponent"
    />
    
    <div v-show="form_visible" class="Panel_tasksContainer">
      <!-- REASONS -->
      <TaskBox :title="$t('message.specific_reasons')" icon="far fa-thumbs-down" v-if="reasons.length > 0">
        <div class="Panel_reasons">
          <div v-for="(reason,index) in reasons" v-bind:key="index">
            <div class="Panel_reasonContainer" :id="'reason_row_' + index">
              <div class="Panel_reasonText">{{ reason.text }}</div>
              <div class="Panel_reasonBtn" v-on:click="selectReason(index)" :id="'reason_btn_' + index">
                <i class="fas fa-eye"></i>
              </div>
            </div>
          </div>
        </div>
      </TaskBox>

      <!-- START INFO MESSAGES -->
      <div class="Panel_taskInfo">
        <div>
          <i class="fas fa-info-circle"></i>
        </div>
        <div>
          {{ $t("message.task_info_create") }}
        </div>
      </div>

      <!-- SUBTASK -->
      <TaskBox :title="$t('message.create_task_txt')" icon="far fa-check-circle">  
        <div class="Panel_tasks">
          <div v-for="(subtask,index) in subtasks" v-bind:key="index" class="Panel_taskContainer">
            <div class="Panel_taskText">{{ subtask }}</div>
            <div class="Panel_taskBtn" v-on:click="removeSubtask(index)" :id="'subtask_btn_' + index">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
          <div class="Panel_taskInputContainer">
            <input class="Panel_taskInput"  v-bind:placeholder="$t('message.type_input_placeholder')" v-on:keyup.enter="addSubtask()"
              v-model="subtask_text" type="text"/>
            <button class="Panel_taskInputBtn" v-on:click="addSubtask()">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
          <div v-show="subtask_error" class="Panel_error">{{ $t("message.empty") }}</div>
        </div>
      </TaskBox>

      <!-- DESCRIPTION -->
      <TaskBox :title="$t('message.extra_description')" icon="fas fa-edit">
        <TextareaForm
         v-bind:placeholderText="$t('message.type_input_placeholder')"
          v-model="description" 
        />
      </TaskBox>

      <!-- ATTACHMENTS -->
      <TaskBox :title="$t('message.attachments')" icon="far fa-clone">
        <label class="Panel_fileLabel">{{ $t("message.file") }}
          <input type="file" id="fileOne" ref="fileOne" class="Panel_fileInput" />
        </label>
        <label class="Panel_fileLabel">{{ $t("message.file") }}
          <input type="file" id="fileTwo" ref="fileTwo" class="Panel_fileInput" />
        </label>
      </TaskBox>

      <button :disabled="btn_disabled" class="Panel_btn" v-on:click="sendJsonTask()" v-show="!btn_loading">
        {{ $t("message.create_task") }}
      </button>
      <button class="Panel_btn_loading" v-show="btn_loading">
        {{ $t("message.creating") }}
      </button>
    </div>

    <ErrorPopUp v-show="popUp_error" v-on:shop-error-popUp="HidePopUp">
      {{ $t("message.error_task_create") }}
    </ErrorPopUp>

  </div>
</template>

<script>
  import TextareaForm from './form/TextareaForm.vue'

  import TaskBox from './TaskBox.vue'
  import FormSection from './FormSection.vue'
  import ErrorPopUp from './ErrorPopUp.vue'

  import axios from 'axios'

  export default {
    data() {
      return {
        reasons: [],
        subtasks: [],
        task_id: 0,
        task_text: '',
        form_visible: false,
        btn_disabled: true,
        subtask_error: false,
        form_data: Object,
        btn_loading: false,
        users: [],
        block_form: false,
        popUp_error: false,
        description: '',
        subtask_text: '',
        file: '',
        fileOne: '',
        fileTwo: ''
      }
    },
    props: {
      mot_data: Array,
      interview_id: String,
      project_id: Number
    },
    watch: {
      subtasks: function() { 
        this.enableBtn()
      }
    },
    components: {
      TaskBox,
      TextareaForm,
      FormSection,
      ErrorPopUp
    },
    methods: {
      deleteDocument(document){
        let confirmText = this.$t("message.confirm_delete_document");
        let confirm = window.confirm(confirmText);
        if (confirm) {
          axios.delete(document.url.delete).then(response => {
            this.documents.splice(
                    this.documents.indexOf(document),
                    1
            )
          }).catch(e => {
            console.log(e)
          })
        }
      },
      onFormDone(value) {
        this.reasons = []
        this.form_data = value
        this.form_visible = value.form_status
        this.mot_data.forEach(item => {
          var reason ={}
          var text = ''
          if(item.id == value.mot_id){
            for (var i=0; i<item.children[0].length; i++ ){
              if(item.children[0][i].option || item.children[0][i].answer){
                if(item.children[0][i].type == "open"){
                  text = item.children[0][i].answer
                }
                if(item.children[0][i].type == "multiple_response"){
                  text = item.children[0][i].option
                }
                reason = {
                  text: text,
                  active: true
                }
                this.reasons.push(reason)
              }
            }
          }
        });
      },
      onUsersDone(value) {
        this.users = value
      },
      selectReason(reason_id) {
        var selected_id = "reason_row_" + reason_id
        var reason_row = document.getElementById(selected_id)
        reason_row.classList.toggle("inactive")
        var icon = document.getElementById('reason_btn_' + reason_id).getElementsByTagName('i')[0]
        icon.classList.toggle("fa-eye-slash")
        icon.classList.toggle("fa-eye")
        this.reasons[reason_id].active = !this.reasons[reason_id].active
      },
      removeSubtask(subtask_id){
        this.subtasks.splice(subtask_id, 1);
      },
      addSubtask(){
        if( this.subtask_text !== '' ){
          this.subtask_error = false
          this.subtasks.push(this.subtask_text)
          this.subtask_text = ''
        }
        else {
          this.subtask_error = true
        }
      },
      enableBtn() {
        if( this.subtasks.length > 0 && this.form_visible)
          this.btn_disabled = false;
      },
      HidePopUp (value) {
        this.popUp_error = value
      },
      sendJsonTask() {
        this.btn_loading = true
        this.block_form = true
        setTimeout(() => {
          this.btn_loading = false
          this.block_form = false
          this.$emit('finish-task', true)
        }, 2500);
        
        let formData = new FormData();

        formData.append('project', this.$props.project_id);
        formData.append('subject', this.form_data.title);
        formData.append('priority', this.form_data.priority);
        formData.append('due', this.form_data.date.toISOString().slice(0,10));
        formData.append('impact', this.form_data.impact);
        formData.append('kpi_id', this.form_data.kpi_id);
        formData.append('recipients', this.users);
        formData.append('description', this.description);
        formData.append('reasons', JSON.stringify(this.reasons));
        formData.append('subtasks', JSON.stringify(this.subtasks));
        formData.append('interview_id', this.$props.interview_id);

        if (this.$refs.fileOne && this.$refs.fileOne.files) {
          formData.append('attachments[]', this.$refs.fileOne.files[0]);
        }
        if (this.$refs.fileTwo && this.$refs.fileTwo.files) {
          formData.append('attachments[]', this.$refs.fileTwo.files[0]);
        }

        let interview_id = this.$props.interview_id
        let helper 

        axios.post('/task-manager/create-task', 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(response => {
          this.$emit('finish-task', true)
          helper = parseInt(document.querySelector('[data-verbatim="' + interview_id + '"]').innerText) + 1
          document.querySelector('[data-verbatim="' + interview_id + '"]').innerText = helper
        })
        .catch(e => {
          this.popUp_error = true
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .Panel {
    width: 100%;
    background: #fff;
    box-shadow: -1px 0px 16px #00000029;
    box-sizing: border-box;
    height: 100vh;
    overflow: auto;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 20px;
    @media (min-width: 470px) {
      width: 470px;
    }
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
    &_error {
      float: left;
      width: 100%;
      color: #721c24;
      border-top: 1px solid #f5c6cb;
      box-sizing: border-box;
      background: #f8d7da;
      padding: 10px;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
    }
    /* INFO TASK */
    &_taskInfo {
      width: 100%;
      background: #0391ea;
      color: #fff;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 40px 1fr;
      border-radius: 3px;
      padding: 5px;
      align-items: center;
      justify-items: center;
      margin-bottom: 20px;
      & i {
        font-size: 35px;
      }
    }
    &_btn {
      background: #0391EA;
      border: 0px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      float: right;
      padding: 3px 30px 3px 6px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      height: 26px;
      box-sizing: border-box;
      @media (min-width: 768px) {
        width: initial;
      }
      &:after {
        position: absolute;
        content: "\f044";
        color: #fff;
        background: rgba(102,102,102,.3);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        text-align: center;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 0px;
        right: 0px;
        font-size: 15px;
        padding: 2px 0px;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: #D0D0D0;
      }
    }
    &_btn_loading {
      background: #28a745;
      border: 0px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      float: right;
      padding: 3px 30px 3px 6px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      height: 26px;
      box-sizing: border-box;
      cursor: not-allowed;
      @media (min-width: 768px) {
        width: initial;
      }
      &:after {
        position: absolute;
        content: "\f110";
        color: #fff;
        background: rgba(102,102,102,.3);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        text-align: center;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 0px;
        right: 0px;
        font-size: 15px;
        padding: 2px 0px;
      }
    }
    &_reasons {
      float: left;
      width: 100%;
      background: #fff;
    }
    &_tasks {
      float: left;
      width: 100%;
      background: #fff;
    }
    &_reasonContainer {
      display: grid;
      grid-template-columns: 1fr 40px;
      border-radius: 3px;
      border: 1px solid #EFEFF0;
      margin-bottom: 5px;
      &.inactive {
        background: #efefef;
        opacity: 0.6;
      }
    }
    &_taskContainer {
      display: grid;
      grid-template-columns: 1fr 40px;
      border-radius: 3px;
      border: 1px solid #efeff0;
      margin-bottom: 5px;
    }
    &_reasonText {
      color: #666;
      font-size: 12px;
      padding: 10px 10px;
    }
    &_taskText {
      color: #666;
      font-size: 12px;
      padding: 10px 10px;
    }
    &_reasonBtn {
      padding: 10px 10px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
    &_taskBtn {
      padding: 10px 10px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
    &_taskInputContainer {
      position: relative;
    }
    &_taskInput {
      width: 100%;
      box-sizing: border-box;
      float: left;
      padding: 10px 40px 10px 10px;
      font-size: 12px;
      color: #666;
      height: 36px;
      border-radius: 3px;
      border: 1px solid #efeff0;
    }
    &_taskInputBtn {
      color: #666;
      width: 40px;
      height: 38px;
      box-sizing: border-box;
      position: absolute;
      right: 0px;
      border: 0px solid transparent;
      font-size: 14px;
      text-align: center;
      background: transparent;
      cursor: pointer;
    }
    &_header {
      padding: 10px 15px;
      width: 100%;
      float: left;
      box-sizing: border-box;
    }
    &_title {
      font-weight: 700;
      color: #666666;
      font-size: 19px;
      float: left;
      line-height: 29px;
    }
    &_cancelBtn {
      background: transparent;
      border: 0px solid transparent;
      font-size: 16px;
      color: #EFEFF0;
      text-align: center;
      float: left;
      padding: 2px 0px;
      cursor: pointer;
      margin-right: 10px;
    }
    &_tasksContainer {
      width: 100%;
      box-sizing: border-box;
      float: left;
      padding: 20px 15px;
    }
    &_switchBtn {
      background: #E2E5ED;
      border: 1px solid #E2E5ED;
      border-radius: 3px;
      font-size: 14px;
      color: #000000;
      text-align: center;
      float: right;
      padding: 5px 10px;
      cursor: pointer;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 600;
      position: relative;
      @media (min-width: 768px) {
        display: none;
      }
      &--arrow {
        position: absolute;
        font-size: 24px;
        left: 10px;
        top: 4px;
      }
    }
    &_file {
      padding-bottom: 20px;
      float: left;
      padding: 0 15px 20px 15px;
      width: 100%;
      box-sizing: border-box;  
    }
    &_fileLabel {
      width: 100%;
      margin: 0px;  
    }
    &_fileInput {
      width: 100%;
      padding: 0px;
      height: 28px;
      &::-webkit-file-upload-button {
        background: #0391ea;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        border: 0px solid transparent;
        box-sizing: border-box;
        height: 26px;
        text-transform: uppercase;
        padding: 3px 6px 3px 6px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
      }
    }
  }
</style>
