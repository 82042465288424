<template>
  <div class="UserPicker_container">
    <div :class="['UserPicker', error ? 'error' : '' ]">
      <div class="UserPicker_toggles">

      <div class="UserPicker_togglesInside">

        <div class="UserPicker_toggleLine">
          <label class="UserPicker_switch">
            <input type="checkbox" v-model="country">
            <span class="UserPicker_slider"></span>
          </label>
          <span class="UserPicker_filter">{{ $t("message.country") }}</span>
        </div>
        <div class="UserPicker_toggleLine">
          <label class="UserPicker_switch">
            <input type="checkbox" v-model="hubmanager">
            <span class="UserPicker_slider"></span>
          </label>
          <span class="UserPicker_filter">{{ $t("message.hub_manager") }}</span>
        </div>
        <div class="UserPicker_toggleLine">
          <label class="UserPicker_switch">
            <input type="checkbox" v-model="hubmember">
            <span class="UserPicker_slider"></span>
          </label>
          <span class="UserPicker_filter">{{ $t("message.hub_member") }}</span>
        </div>
        <div class="UserPicker_toggleLine">
          <label class="UserPicker_switch">
            <input type="checkbox" v-model="dealermanager">
            <span class="UserPicker_slider"></span>
          </label>
          <span class="UserPicker_filter">{{ $t("message.dealer_manager") }}</span>
        </div>
        <div class="UserPicker_toggleLine">
          <label class="UserPicker_switch">
            <input type="checkbox" v-model="dealermember">
            <span class="UserPicker_slider"></span>
          </label>
          <span class="UserPicker_filter">{{ $t("message.dealer_member") }}</span>
        </div>

        </div>

      </div>
      <div class="UserPicker_users">
        <input class="UserPicker_input" v-model="search"  v-bind:placeholder="$t('message.input_user_placeholder')" />
        <div class="UserPicker_usersList">
          <div class="UserPicker_user" v-for="user in filteredUsers" v-bind:key="user.user_id" v-on:click="addSelectedUser(user)" :category="user.role">
            {{ user.display_name }}
          </div> 
        </div>
      </div>
    </div>
    <div v-if="selected_users.length > 0">
      <div v-for="user in selected_users" v-bind:key="user.user_id" class="UserPicker_userContainer">
        <span>{{ user.display_name }}</span>
        <button class="UserPicker_userBtn" v-on:click="removeSelectedUser(user)">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  //import usersJSON from '../assets/users.json'
  import axios from 'axios'

  export default {
    data() {
      return {
        country: true, 
        hubmanager: true,
        hubmember: true,
        dealermanager: true,
        dealermember: true,
        users: [],
        users_copy: [],
        search: '',
        selected_users: [],
        selected_users_id: []
      }
    },
    props: {
      error: Boolean,
      pass_users: Array
    },
    computed: {
      filteredUsers: function () {
        var users = this.users;
        var search = this.search;
        var searchString;
  
        if(!search){
          return users;
        }

        searchString = search.trim().toLowerCase();

        users = users.filter(function(item){
          if(
            item.display_name && item.display_name.toLowerCase().indexOf(search) !== -1
              ||
            item.email && item.email.toLowerCase().indexOf(search) !== -1
          ){
            return item;
          }
        })

        return users;
      }
    },
    mounted () {
      axios.get('/api-auth/user').then(response => {
        this.users = response.data
        this.users_copy = response.data
        if(this.pass_users && this.pass_users.length > 0){
          this.runPassUsers()  
        }
      })
      .catch(e => {
        console.log(e)
      })
    },
    watch: {
      country: function(){
        this.toggleCategories("country",this.country)
      },
      hubmanager: function(){
        this.toggleCategories("hub_manager",this.hubmanager)
      },
      hubmember: function(){
        this.toggleCategories("hub_member",this.hubmember)
      },
      dealermanager: function(){
        this.toggleCategories("dealer_manager",this.dealermanager)
      },
      dealermember: function(){
        this.toggleCategories("dealer_member",this.dealermember)
      },
      pass_users: function(){
        if(this.users.length > 0){
          this.runPassUsers()
        }  
      }
    },
    methods: {    
      runPassUsers (){
        this.users.forEach(user => {
          this.pass_users.forEach(pass_user => {
            if(pass_user == user.user_id){
              this.addSelectedUser(user)
            }
          })
        })
      },
      addSelectedUser(user){
        this.selected_users.push(user)
        this.selected_users_id.push(user.user_id)
        this.users = this.users.filter(function( obj ) {
          return obj.display_name !== user.display_name;
        });
        this.$emit('selectedUsers', this.selected_users_id)
      },
      removeSelectedUser(user){
        var helper
        this.users.push(user)
        helper = this.selected_users_id.indexOf(user.user_id)
        this.selected_users_id.splice(helper, 1)
        this.selected_users = this.selected_users.filter(function( obj ) {
          return obj.display_name !== user.display_name;
        });
        this.$emit('selectedUsers', this.selected_users_id)
        this.users.sort()
        this.toggleCategories("Country",this.country)
        this.toggleCategories("Hubmanager",this.hubmanager)
        this.toggleCategories("Hubmember",this.hubmember)
        this.toggleCategories("dealer_manager",this.dealermanager)
        this.toggleCategories("Dealermember",this.dealermember)
      },
      toggleCategories(cat, var_cat){
        var users = document.getElementsByClassName("UserPicker_user")
        if(var_cat == true){
          users.forEach(element => {
            if(element.getAttribute("category").includes(cat)){
              element.style.display = "block"
            }
          });
        }
        else{
          users.forEach(element => {
            if(element.getAttribute("category").includes(cat)){
              element.style.display = "none"
            }
          });
        }
      },
      compare(a,b){
        // Use toUpperCase() to ignore character casing
        const nameA = a.display_name.toUpperCase();
        const nameB = b.display_name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      }
    }
  }
</script>

<style scoped lang="less">
  .UserPicker {
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    margin-bottom: 10px;
    background: #FBFBFB;
    border-radius: 3px;
    @media (min-width: 768px) {
      grid-template-columns: 40% 60%;
    }
    &.error {
      border: 1px solid #ff2400;
      background: rgba(255, 0, 0, 0.05);
    }

    &_container {
      float: left;
      width: 100%;
    }
    &_toggles {
      width: 100%;
      padding: 10px 5px;
    }
    &_togglesInside {
      width: 100%;
      border-right: 1px solid #E8E8E8;
    }
    &_toggleLine {
      color: #666666;
      text-transform: uppercase;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      padding: 5px 0px;
    }
    &_filter {
      position: relative;
      top: 2px;
    }
    &_users {
      width: 100%;
      position: relative;
    }
    &_input {
      border: 1px solid #EFEFF0;
      box-sizing: border-box;
      color: #666666;
      width: calc(100% - 14px);
      height: 30px;
      font-size: 12px;
      padding: 5px 10px;
      float: left;
      border-radius: 3px;
      margin: 10px 7px 5px 7px;
    }
    &_usersList {
      height: 200px;
      float: left;
      width: 100%;
      overflow-y: auto;
      position: relative;
      bottom: 0px;
      overflow-x: hidden;
      @media (min-width: 768px) {
        height: calc(100% - 41px);
        position: absolute;
      }
    }
    &_user {
      color: #707070;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      padding: 4px 9px;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      word-break: break-all;
      &:hover {
        background: rgba(3, 145, 234, 0.2);
      }
      & span {
        color: #cccccc;
        font-size: 13px;
        position: relative;
        top: -2px;
      }
    }
    /* TOGGLE */
    &_switch {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 20px;
      margin-right: 5px;
      margin-bottom: 0px;
    }
    &_switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    &_slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s;
      border-radius: 34px;
      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 3px;
        bottom: 2px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
      }
    }
    &_switch input:checked + &_slider {
      background-color: #0391EA;
    }
    &_switch input:focus + &_slider {
      box-shadow: 0 0 1px #0391EA;
    }
    &_switch input:checked + &_slider:before {
      transform: translateX(17px);
    }
    /* CHOSEN USER */
    &_userContainer {
      float: left;
      border: 1px solid #efeff0;
      border-radius: 3px;
      padding: 5px 6px;
      margin-right: 5px;
      margin-bottom: 5px;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      color: #666;
      background: #fff;
      box-shadow: -1px 0 4px rgba(0,0,0,.1);
    }
    &_userBtn {
      background: transparent;
      border: 0px solid transparent;
      padding: 0px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee; 
    border-radius: 10px;
  }
 
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
  }
</style>
