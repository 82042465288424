<template>
  <div id="app">
    <!-- VOC verbatim -->
    <Panel 
      v-bind:class="{ 'open' : openPanel, 'move-right' : moveRight }" 
      v-on:create-task="createTask" 
      v-on:switch-view="switchView"
      v-on:cancel-panel="cancelPanel"
      :data_dealer="data_dealer"
      :pdf_link="pdf_link"
    />

    <!-- Create Task linked to verbatim -->
    <Panel2
      v-bind:class="{ 'open' : moveRight }" 
      v-on:finish-task="finishTask" 
      v-on:cancel-task="cancelTask"
      v-on:switch-view="switchView"
      :interview_id="data_dealer.codeInterview"
      :project_id="data_dealer.projectId"
      ref="panelComponent"
      :mot_data="mot_data" 
    />
  </div>
</template>

<script>
import Panel from './components/Panel.vue'
import Panel2 from './components/Panel2.vue'

import axios from 'axios'

export default {
  name: 'app',
  data() {
    return {
      data_dealer: [],
      openPanel: false,
      moveRight: false,
      mot_data: [],
      pdf_link: '',
      row_id: null
    }
  },
  mounted: function() {
    var ref = this
    document.querySelectorAll('.js-viewVerbatim > *').forEach(item => {
      if (!item.classList.contains('notClickable')) {
        item.addEventListener('click', e => {
          ref.startPanel(item.parentNode.id)
        })
      }
    })
    this.$i18n.locale = document.querySelector('#js-language') ? document.querySelector('#js-language').textContent : 'en_US'
  },
  components: {
    Panel,
    Panel2
  },
  methods: {
    finishTask(value){
      this.moveRight = !value
      this.openPanel = !value
      var ref = this
      setTimeout(function(){
        Object.assign(ref.$data, ref.$options.data.call(ref))
        ref.clean()
      }, 1500)
      if (document.getElementById(this.row_id) !== null) {
        document.getElementById(this.row_id).classList.remove('clicked')
      }
    },
    cancelTask(value){
      this.moveRight = !value
      var ref = this
      setTimeout(function(){
        ref.clean()
      }, 1500)
    },
    cancelPanel(value){
      this.moveRight = !value
      this.openPanel = !value
      var ref = this
      setTimeout(function(){
        ref.clean()
      }, 1500)
      if (document.getElementById(this.row_id) !== null) {
        document.getElementById(this.row_id).classList.remove('clicked')
      }
    },
    createTask(value){
      this.moveRight = value
      this.clean()
    },
    startPanel(apiId){
      this.row_id = apiId
      var apiLink = apiId
      this.pdf_link = apiId.replace('json','pdf')
      axios.get(apiLink).then(
        response => {
          this.data_dealer = response.data
          this.openPanel = !this.openPanel
          this.createMot()
        }
      )
      /* prevent highlight multirows */
      var elems = document.querySelectorAll(".js-viewVerbatim");
      if(elems.length > 0){
        [].forEach.call(elems, function(el) {
          el.classList.remove("clicked");
        });
      }
      if (document.getElementById(this.row_id) !== null) {
        document.getElementById(this.row_id).classList.add('clicked')
      }

      console.log('App.vue :: startPanel()');
    },
    createMot() {
      this.mot_data = []
      this.data_dealer.questions.forEach(item => {
        //uncomment this when API will be fixed
        //if (item.taskItem == true){
          this.mot_data.push(item)
        //}
      })
    },
    clean() {
      var panelComponent = this.$refs.panelComponent 
      var formComponent = this.$refs.panelComponent.$refs.formComponent
      var keep_kpi = formComponent.$data.kpi_options
      var usersComponent = this.$refs.panelComponent.$refs.formComponent.$refs.usersComponent
      Object.assign(panelComponent.$data, panelComponent.$options.data.call(this))
      Object.assign(formComponent.$data, formComponent.$options.data.call(this))
      formComponent.$data.kpi_options = keep_kpi
      usersComponent.$data.country = true 
      usersComponent.$data.hubmanager = true
      usersComponent.$data.hubmember = true
      usersComponent.$data.dealermanager = true
      usersComponent.$data.dealermember = true
      usersComponent.$data.search = ''
      usersComponent.$data.selected_users = []
      usersComponent.$data.users = usersComponent.$data.users_copy

      panelComponent.$refs.fileOne.value = ''
      panelComponent.$refs.fileTwo.value = ''

      this.cleanErrors()
    },
    switchView(value) {
      this.moveRight = value
    },
    cleanErrors(){
      var formComponent = this.$refs.panelComponent.$refs.formComponent
      formComponent.cal_data_error = false
      formComponent.title_error = false
      formComponent.priority_error = false
      formComponent.impact_error = false
      formComponent.users_error = false
    }
  }
}
</script>
<style scoped lang="less">

/deep/ body {
  overflow-x: hidden;
}

.BtnContainer {
  width: 100px;
  float: left;
  cursor: pointer;
}

/deep/ .Panel {
  position: fixed;
  right: -490px;
  transition: right 1s;
  float: left;
  top: 0px;
  z-index: 9999;
  &.open {
    right: 0px;
  }
  &.move-right {
    right: 0px;
    @media (min-width: 470px) {
      right: 470px;
    }
  }
}
</style>
