<template>
  <div :class="['Select_container', error ? 'error' : '' ]">
    <div class="Select_prefix">
      <i :class="icon"></i>
    </div>
    <select :class="['Select', error ? 'error' : '' ]" :value="value" 
      @input="$emit('input', $event.target.value)" :disabled=disabled>
      <option :value="null" v-if="placeholder">{{ placeholder }}</option>
      <option v-for="item in options" v-bind:key="item.id" v-bind:value="item.id">
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      options: Array,
      placeholder: String,
      value: String,
      error: Boolean,
      disabled: Boolean,
      icon: String
    }
  }
</script>

<style scoped lang="less">
  .Select {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 12px;
    border: 1px solid #EFEFF0;
    border-radius: 3px;
    padding: 3px 3px 3px 32px;
    background: #FBFBFB;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
    &_prefix {
      background: #efeff0;
      color: #666;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px 2px;
    }
    &_container {
      width: 100%;
      position: relative;
      height: 30px;
      float: left;
      &.error .Select_prefix {
        background: #ff2400;
        color: #fff;
      }
    }
    &.error {
      border-color: #ff2400;
    }
  }
</style>