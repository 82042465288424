<template>
  <div class="Textarea_container">
    <textarea 
      :placeholder=placeholderText 
      class="Textarea" 
      row=3 
      :disabled=disabled
      :value=value
      @input="$emit('input', $event.target.value)">
    </textarea>
  </div>
</template>

<script>
  export default {
    props: {
      placeholderText: String,
      disabled: Boolean,
      value: String,
    }
  }
</script>
      
<style scoped lang="less">
  .Textarea {
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    border: 1px solid #efeff0;
    border-radius: 3px;
    padding: 5px 10px;
    font-family: 'Open Sans', sans-serif;
    resize: vertical;
    min-height: 60px;
    float: left;
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
    &_container {
      width: 100%;
      float: left;
    }
  }
</style>