<template>
  <div v-bind:id=question.id class="Numeric">
    <div class="Numeric_header">
      <div v-bind:class="['Numeric_score', scoreColor ]" v-if="question.scoreVal">
        {{ question.scoreVal }}
      </div>
      <div :class="['Numeric_title', question.scoreVal ? '' : 'full-width' ]">{{ question.motLabel }}</div>
    </div>
    <div class="Numeric_body">
      <p class="Numeric_answer" v-if="question.answer && question.answer != ''">{{ question.answer }}</p>
      <div v-if="question.children && question.children[0].length > 0" class="Numeric_content">
        <div v-for="child in question.children[0]" v-bind:key="child.id">

          <div v-if="child.answer && child.answer != ''" class="Numeric_child">
            <label>{{ $t("message.option") }}:</label>
            {{ child.answer }}
          </div>

          <div v-if="child.option && child.option != ''" class="Numeric_child">
            <label>{{ $t("message.option") }}:</label>
            {{ child.option }}
          </div>

          <div v-if="child.options && child.options != ''" class="Numeric_child">
            <div v-for="option in child.options" v-bind:key="option.id">
              <label>{{ $t(child.motLabel) }}:</label>
              {{ option }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      question: {
        type: Object
      }
    },
    computed: {
      scoreColor: function() {
        var color
        if (this.question.scoreVal < 7)
          color = 'red' 
        if (this.question.scoreVal > 6 && this.question.scoreVal < 9)
          color = 'orange'
        if (this.question.scoreVal > 8)
          color = 'green'
        return color
      }
    }
  }
</script>

<style scoped lang="less">
  .Numeric {
    font-family: 'Open sans', sans-serif;
    background: #fff;
    border: 1px solid #EFEFF0;
    border-radius: 2px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    &_header {
      width: 100%;
      float: left;
      box-sizing: border-box;
    }
    &_score {
      width: 26px;
      padding: 2px;
      text-align: center;
      float: left;
      box-sizing: border-box;
      font-size: 16px;
      background: #B7B8BA;
      color: #fff;
      font-weight: 600;
      border-radius: 2px 0px 0px 0px;
      height: 26px;
      &.red {
        background: #FF1919;
      }
      &.orange {
        background: #DF9949;
      }
      &.green {
        background: #7DAD7D;
      }
    }
    &_title {
      float: left;
      width: calc(100% - 26px);
      box-sizing: border-box;
      color: #666666;
      font-size: 11px;
      padding: 0px 10px;
      text-transform: uppercase;
      min-height: 26px;
      font-weight: 600;
      line-height: 26px;
      &.full-width {
        width: 100%;
      }
    }
    &_body {
      width: 100%;
      float: left;
      box-sizing: border-box;
    }
    &_content {
      width: 100%;
      float: left;
      box-sizing: border-box;
      padding: 10px;
    }
    &_answer {
      width: 100%;
      float: left;
      box-sizing: border-box;
      color: #999999;
      font-size: 14px;
      font-style: italic;
      margin: 0px;
      padding: 10px 10px;
    }
    &_child {
      border-radius: 2px;
      padding: 10px;
      font-style: italic;
      font-size: 14px;
      color: #999999;
      margin-bottom: 5px;
      border: 1px solid #EFEFF0;
    }
  }
</style>
